<template>
  <div class="page-catalog__sorting-modal">
    <div class="page-catalog__sorting-container">
      <div class="page-catalog__sorting-wrapper">
        <!-- <div class="page-catalog__sorting-item">
          <label class="sorting-label sorting-label--title">Выбор Party Club</label>
        </div> -->
        <div
          v-for="sort in sorts"
          :key="sort.label"
          class="page-catalog__sorting-item"
        >
          <!-- <input
            type="checkbox"
            :id="sort.label"
            name="sortBy"
            v-model="sort.isChecked"
            @change="checkSort(sort)"
          /> -->
          <label
            @change="checkSort(sort)"
            @click="sortingBtn(sort)"
            class="sorting-label"
          >
            {{ sort.label }}
          </label>
        </div>
      </div>
      <!-- <button class="page-catalog__sorting-show" @click="sortingBtn">
        {{ $t("Show") }}
      </button> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { actionTypes } from "@/store/modules/catalog"

export default {
  name: "DiscountSort",
  data() {
    return {
      selectedSort: null,
      isChecked: false,
      sort: null,
      sorts: [
        {
          field: "id",
          sortType: "asc",
          label: this.$t("choice"),
          isChecked: false,
        },
        {
          field: "new_price",
          sortType: "asc",
          label: this.$t("catalog.priceIncreasing"),
          isChecked: false,
        },
        {
          field: "new_price",
          sortType: "desc",
          label: this.$t("catalog.priceDecreasing"),
          isChecked: false,
        },
        {
          field: "discount",
          sortType: "desc",
          label: this.$t("catalog.discountDecreasing"),
          isChecked: false,
        },
        {
          field: "discount",
          sortType: "asc",
          label: this.$t("catalog.discountIncreasing"),
          isChecked: false,
        },
      ],
    }
  },
  methods: {
    ...mapActions("catalogModule", { changeSortBy: actionTypes.changeSortBy }),
    checkSort(item) {
      if (item.id === this.selectedSort?.id) {
        this.isChecked = true
      }
      this.selectedSort = item
      this.changeSortBy(item)
      this.sort = item
      this.sorts.forEach((e) => {
        e.isChecked = false
      })
      item.isChecked = true
    },
    sortingBtn(item) {
      this.changeSortBy(item)
      this.$emit("sort-start")
      this.$emit("sort-btn")
      this.$emit("sort-obj", item)
    },
  },
}
</script>
